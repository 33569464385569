import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Hey I am John and i am very excited to train new people who wants to stay fit, please join us this is the best place where you will get the best training',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:'Hello i am Franklin ,last year i joined this gym and to be very honest this is the best gym where i have ever trained ,the environment is great and the trainers are very professional and are the best in their feild',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
